<template>
  <q-page padding>
    <q-card flat bordered>
      <q-card-section>
        <div class="text-h6">系统用户管理</div>
      </q-card-section>
      <q-separator/>
      <q-card-section>
        <x-responsive-table
          ref="table"
          url="/sysUser/sysUserPage"
          v-model:param="param"
          :paramProps="paramProps"
          :columns="columns"
          row-key="id"
          title-prop="username"
        >
          <template v-slot:search>
            <q-input v-model="param.username" label="用户名" placeholder="请输入用户名" clearable dense outlined/>
            <q-input v-model="param.realName" label="真实姓名" placeholder="请输入真实姓名" clearable dense outlined/>
            <q-input v-model="param.email" label="邮箱" placeholder="请输入邮箱" clearable dense outlined/>
            <q-input v-model="param.mobile" label="手机号" placeholder="请输入手机号" clearable dense outlined/>
            <q-select v-model="param.enable" label="是否启用" :options="$dict.enable" map-options emit-value clearable dense outlined/>
            <x-datetime v-model="param.createTimeStart" label="创建时间(起)" clearable dense outlined/>
            <x-datetime v-model="param.createTimeEnd" label="创建时间(止)" clearable dense outlined/>
          </template>
          <template v-slot:top-opes>
            <q-btn-group v-if="$hasAuth('user-add')" outline>
              <q-btn label="添加用户" icon="add" color="primary" outline @click="toAdd"/>
            </q-btn-group>
          </template>
          <template v-slot:column-avatar="{row}">
            <q-avatar color="grey-2">
              <img v-if="row.avatar" :src="row.avatar"/>
            </q-avatar>
          </template>
          <template v-slot:column-roles="{row}">
            <q-chip
              v-for="(role,index) in row.roles"
              :key="index"
              :label="role.roleName"
            />
          </template>
          <template v-slot:opes="{row}">
            <q-btn icon="more_vert" color="grey-7" round flat>
              <q-menu cover auto-close>
                <q-list>
                  <q-item v-if="$hasAuth('user-detail')" clickable @click="toDetail(row.id)">
                    <q-item-section class="text-primary">详情</q-item-section>
                  </q-item>
                  <q-item v-if="$hasAuth('user-edit')" clickable @click="toEdit(row.id)">
                    <q-item-section class="text-primary">编辑</q-item-section>
                  </q-item>
                  <q-item v-if="$hasAuth('user-pwd-reset')" clickable @click="toPwdReset(row.id)">
                    <q-item-section class="text-primary">重置密码</q-item-section>
                  </q-item>
                  <q-item v-if="$hasAuth('user-unlock') && row.locked" clickable @click="toUnlock(row.id)">
                    <q-item-section class="text-primary">解锁用户</q-item-section>
                  </q-item>
                  <q-item v-if="$hasAuth('user-delete')" clickable @click="toDelete(row.id)">
                    <q-item-section class="text-negative">删除</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </template>
        </x-responsive-table>
      </q-card-section>
    </q-card>
  </q-page>
  <teleport to="body">
    <q-dialog v-model="showEditDialog" persistent>
      <q-card style="width:470px;max-width:90vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">用户信息</div>
          <q-space/>
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="scroll" style="max-height:60vh">
          <sys-user-form ref="formEle" :data="form" :readonly="readonly"/>
        </q-card-section>
        <template v-if="!readonly">
          <q-separator/>
          <q-card-section class="text-right">
            <q-btn color="primary" label="保存" unelevated @click="save"/>
          </q-card-section>
        </template>
      </q-card>
    </q-dialog>
    <q-dialog v-model="showPwdResetDialog" persistent>
      <q-card style="width:470px;max-width:90vw;">
        <q-card-section class="row items-center">
          <div class="text-h6">重置密码</div>
          <q-space/>
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="scroll" style="max-height:60vh">
          <sys-user-pwd-reset-form ref="pwdResetFormEle" :data="pwdResetForm"/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="text-right">
          <q-btn color="primary" label="确定" unelevated @click="pwdReset"/>
        </q-card-section>
      </q-card>
    </q-dialog>
  </teleport>
</template>

<script>
import { XResponsiveTable } from '../../../components/responsive-table'
import { XDatetime } from '../../../components/datetime'
import { getCurrentInstance, ref } from 'vue'
import SysUserForm from './SysUserForm'
import SysUserPwdResetForm from './SysUserPwdResetForm'

export default {
  components: {
    XResponsiveTable,
    XDatetime,
    SysUserForm,
    SysUserPwdResetForm
  },
  setup () {
    const { proxy } = getCurrentInstance()
    const columns = ref([
      { label: 'ID', name: 'id', field: 'id', align: 'left', sortable: true },
      { label: '用户名', name: 'username', field: 'username', align: 'left', sortable: true },
      { label: '角色', name: 'roles', align: 'left' },
      { label: '真实姓名', name: 'realName', field: 'realName', align: 'left', sortable: true },
      { label: '职位', name: 'position', field: 'position', align: 'left', sortable: true },
      { label: '邮箱', name: 'email', field: 'email', align: 'left', sortable: true },
      { label: '手机号', name: 'mobile', field: 'mobile', align: 'left', sortable: true },
      { label: '是否启用', name: 'enable', field: 'enable', align: 'left', format: proxy.$dictFormatter('enable'), sortable: true },
      { label: '创建时间', name: 'createTime', field: 'createTime', align: 'left', sortable: true },
      { label: '修改时间', name: 'updateTime', field: 'updateTime', align: 'left', sortable: true }
    ])
    const param = ref({
      username: null,
      realName: null,
      nickname: null,
      email: null,
      mobile: null,
      enable: null,
      gender: null,
      birthdayStart: null,
      birthdayEnd: null,
      createTimeStart: null,
      createTimeEnd: null,
      exclude: ['remark', 'roleIds']
    })
    const paramProps = ref({
      enable: { type: Boolean },
      gender: { type: Number }
    })
    const form = ref(null)
    const readonly = ref(false)
    const showEditDialog = ref(false)

    const pwdResetForm = ref(false)
    const showPwdResetDialog = ref(false)

    async function refresh () {
      await proxy.$refs.table.refresh()
    }

    function toAdd () {
      form.value = null
      readonly.value = false
      showEditDialog.value = true
    }

    async function toDetail (id) {
      form.value = await proxy.$server.post('sysUser/getSysUserDetail', { id })
      readonly.value = true
      showEditDialog.value = true
    }

    async function toEdit (id) {
      form.value = await proxy.$server.post('sysUser/getSysUserDetail', { id })
      readonly.value = false
      showEditDialog.value = true
    }

    async function toDelete (id) {
      await proxy.$message.confirm('确定要删除该项吗？')
      await proxy.$server.post('/sysUser/deleteSysUser', { id })
      proxy.$message.tipsSuccess('删除成功')
      proxy.$refs.table.refresh()
    }

    async function toUnlock (id) {
      await proxy.$message.confirm('确定要解锁该用户吗？')
      await proxy.$server.post('/sysUser/unlockSysUser', { id })
      proxy.$message.tipsSuccess('解锁成功')
      proxy.$refs.table.refresh()
    }

    async function save () {
      const param = await proxy.$refs.formEle.validateAndGetForm()
      param.roleIds = param.roles.map((v) => v.roleId)
      delete param.roles
      if (param.id) {
        await proxy.$server.post('/sysUser/updateSysUser', param)
      } else {
        await proxy.$server.post('/sysUser/createSysUser', param)
      }
      proxy.$message.tipsSuccess('保存成功')
      showEditDialog.value = false
      refresh()
    }

    async function toPwdReset (id) {
      pwdResetForm.value = { id }
      showPwdResetDialog.value = true
    }

    async function pwdReset () {
      const param = await proxy.$refs.pwdResetFormEle.validateAndGetForm()
      await proxy.$server.post('/sysUser/resetSysUserPassword', param)
      proxy.$message.tipsSuccess('密码已重置')
      showPwdResetDialog.value = false
      refresh()
    }

    return {
      columns,
      param,
      paramProps,
      form,
      readonly,
      showEditDialog,
      pwdResetForm,
      showPwdResetDialog,
      toAdd,
      toDetail,
      toEdit,
      toDelete,
      toUnlock,
      save,
      toPwdReset,
      pwdReset
    }
  }
}
</script>
