<template>
  <q-form ref="formEle" class="q-gutter-md">
    <template v-if="readonly">
      <q-field
        v-if="form.id"
        label="ID"
        :readonly="readonly"
        stack-label
        dense
        outlined
      >
        <span>{{ form.id }}</span>
      </q-field>
    </template>
    <q-input
      v-model="form.username"
      :rules="rules.username"
      label-slot
      :readonly="readonly"
      maxlength="32"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>用户名</span>
      </template>
    </q-input>
    <q-input
      v-if="!form.id"
      v-model="form.password"
      :rules="rules.password"
      type="password"
      label-slot
      :readonly="readonly"
      maxlength="32"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>初始密码</span>
      </template>
    </q-input>
    <q-select
      :modelValue="form.roles.map(v => { return { id: v.roleId, roleName: v.roleName } })"
      @update:modelValue="updateRoles"
      label="角色"
      :readonly="readonly"
      :options="roleList"
      option-value="id"
      option-label="roleName"
      use-chips
      multiple
      map-options
      dense
      clearable
      outlined
      hide-bottom-space
    />
    <q-input
      v-model="form.realName"
      label="真实姓名"
      :readonly="readonly"
      maxlength="64"
      dense
      clearable
      outlined
      hide-bottom-space
    />
    <q-input
      v-model="form.email"
      :rules="rules.email"
      label="邮箱"
      :readonly="readonly"
      maxlength="64"
      dense
      clearable
      outlined
      hide-bottom-space
    />
    <q-input
      v-model="form.mobile"
      :rules="rules.mobile"
      label="手机号"
      :readonly="readonly"
      maxlength="32"
      dense
      clearable
      outlined
      hide-bottom-space
    />
    <q-input
      v-model="form.position"
      label="职位"
      :readonly="readonly"
      maxlength="64"
      dense
      clearable
      outlined
      hide-bottom-space
    />
    <q-field
      v-model="form.enable"
      label="是否启用"
      :readonly="readonly"
      dense
      stack-label
      clearable
      outlined
      hide-bottom-space
    >
      <q-option-group
        v-model="form.enable"
        :options="$dict.enable"
        :disable="readonly"
        inline
      />
    </q-field>
    <q-input
      v-model="form.remark"
      label="备注"
      type="textarea"
      :readonly="readonly"
      maxlength="255"
      dense
      clearable
      outlined
      hide-bottom-space
    />
    <template v-if="readonly">
      <q-field
        v-if="form.createTime"
        label="创建时间"
        :readonly="readonly"
        stack-label
        dense
        outlined
      >
        <span>{{ form.createTime }}</span>
      </q-field>
      <q-field
        v-if="form.updateTime"
        label="更新时间"
        :readonly="readonly"
        stack-label
        dense
        outlined
      >
        <span>{{ form.updateTime }}</span>
      </q-field>
    </template>
  </q-form>
</template>

<script>
import { getCurrentInstance, nextTick, ref, watch } from 'vue'
import { qEmail, qNotBlank, qPassword } from '../../../utils/validate-utils'

export default {
  props: {
    data: { type: Object },
    readonly: { type: Boolean, default: false }
  },
  setup (props) {
    const { proxy } = getCurrentInstance()
    const form = ref(dataToForm(props.data))
    const rules = ref({
      username: [qNotBlank],
      email: [qEmail],
      password: [qNotBlank, qPassword]
    })
    const roleList = ref([])

    watch(() => props.data, (value) => {
      form.value = dataToForm(value)
      resetValidation()
    })

    function dataToForm (data) {
      return Object.assign({
        username: null,
        realName: null,
        nickname: null,
        position: null,
        email: null,
        mobile: null,
        gender: null,
        birthday: null,
        avatar: null,
        enable: true,
        remark: null,
        roles: []
      }, data)
    }

    async function validate () {
      return await proxy.$refs.formEle.validate()
    }

    async function resetValidation () {
      nextTick(() => proxy.$refs.formEle.resetValidation())
    }

    function getForm () {
      return Object.assign({}, form.value)
    }

    async function validateAndGetForm () {
      if (await validate()) {
        return getForm()
      }
      throw new Error('Form valid error')
    }

    async function loadRoles () {
      roleList.value = await proxy.$server.post('/sysRole/sysRoleList', { enable: true })
    }

    function updateRoles (roles) {
      form.value.roles = roles ? roles.map(v => {
        return { roleId: v.id, roleName: v.roleName }
      }) : []
    }

    if (!props.readonly) {
      loadRoles()
    }

    return {
      form,
      rules,
      roleList,
      validate,
      resetValidation,
      getForm,
      validateAndGetForm,
      updateRoles
    }
  }
}
</script>
