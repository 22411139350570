<template>
  <q-form ref="formEle" class="q-gutter-md">
    <q-input
      v-model="form.password"
      :rules="rules.password"
      type="password"
      label-slot
      maxlength="32"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>设置新密码</span>
      </template>
    </q-input>
    <q-input
      v-model="form.password2"
      :rules="rules.password2"
      type="password"
      label-slot
      maxlength="32"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>再次输入新密码</span>
      </template>
    </q-input>
  </q-form>
</template>

<script>
import { getCurrentInstance, nextTick, ref, watch } from 'vue'
import { qNotBlank, qPassword } from '../../../utils/validate-utils'

export default {
  props: {
    data: { type: Object }
  },
  setup (props) {
    const { proxy } = getCurrentInstance()
    const form = ref(dataToForm(props.data))
    const rules = ref({
      password: [qNotBlank, qPassword],
      password2: [
        qNotBlank,
        (v) => {
          return v === form.value.password || '两次输入的密码不一致'
        }
      ]
    })

    watch(() => props.data, (value) => {
      form.value = dataToForm(value)
      resetValidation()
    })

    function dataToForm (data) {
      return Object.assign({
        password: null,
        password2: null
      }, data)
    }

    async function validate () {
      return await proxy.$refs.formEle.validate()
    }

    async function resetValidation () {
      nextTick(() => proxy.$refs.formEle.resetValidation())
    }

    function getForm () {
      return Object.assign({}, form.value)
    }

    async function validateAndGetForm () {
      if (await validate()) {
        return getForm()
      }
      throw new Error('Form valid error')
    }

    return {
      form,
      rules,
      validate,
      resetValidation,
      getForm,
      validateAndGetForm
    }
  }
}
</script>
